import React from "react";

class Header extends React.Component {
  render() {
    return (
      <header className="header">
        <a href="/"><img src="/images/logo.png" /></a>
      </header>
    );
  }
}

export default Header;
